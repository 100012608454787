import { RootState } from 'types/root';
import { UCSState } from 'types/ucs';

export const ucsTree = (state: RootState): UCSState => state.ucs || {};

export const isProcessQuoteLoading = (state: RootState): boolean => ucsTree(state).isProcessQuoteLoading || false;

export const isProcessQuoteFailed = (state: RootState): boolean => ucsTree(state).isProcessQuoteFailed || false;

export const isProcessQuoteSuccessful = (state: RootState): boolean => ucsTree(state).isProcessQuoteSuccessful || false;
