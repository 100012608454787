import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Button, Dialog, MenuSeparator, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { isProcessRenewalFailed } from 'modules/cog/cog-selector';
import { sesssionCancelRedirectUrl } from 'modules/session-details';
import { isProcessQuoteFailed } from 'modules/ucs/ucs-selector';
import st from 'utils/shared-translations';

import './feedback.css';

const PaymentFailure: FC = (): JSX.Element => {
  const intl = useIntl();

  const selectedIsProcesssQuoteFailed = useAppSelector(isProcessQuoteFailed);
  const selectedIsProcessRenewalFailed = useAppSelector(isProcessRenewalFailed);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const goToCheckout = () => window.location.assign(selectedCancelRedirectUrl);

  return (
    <Dialog
      open={selectedIsProcesssQuoteFailed || selectedIsProcessRenewalFailed}
      closable={false}
      size="large"
      title={intl.formatMessage(st['feedback.failure.something.went.wrong'])}
      actions={
        <Button className="feedback__ctas" size="medium" variant="primary" onClick={goToCheckout}>
          {intl.formatMessage(st['back.to.previous.page'])}
        </Button>
      }
    >
      <div className="feedback__body">
        <AlertOutlinedIcon size="50px" color="#d33a61" />
        <Typography tag="h3" variant="body-medium-strong" className="feedback__body-info" gutterBottom>
          {intl.formatMessage(st['feedback.failure.payment.process'])}
        </Typography>
        <Typography tag="p" variant="body-small" className="feedback__failure-info">
          <FormattedMessage
            {...st['feedback.failure.payment.info']}
            values={{
              link: (msg: ReactNode) => (
                <a href="https://www.goto.com/learn-more" target="_blank" rel="noopener noreferrer">
                  {msg}
                </a>
              ),
            }}
          />
        </Typography>
        <MenuSeparator className="feedback__menu-separator" />
      </div>
    </Dialog>
  );
};

export default PaymentFailure;
