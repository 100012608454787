import { RootState } from 'types/root';
import { AddDDResp, TransactionsDDState, UseDDPayResp } from 'types/transaction-dd';

export const transactionsDDTree = (state: RootState): TransactionsDDState => state.transactionsDD || {};

export const transactionsDDIsLoading = (state: RootState): boolean => transactionsDDTree(state).isLoading || false;

export const addDDResp = (state: RootState): AddDDResp => transactionsDDTree(state).addDDResp || {};

export const useDDPayResp = (state: RootState): UseDDPayResp => transactionsDDTree(state).useDDPayResp || {};
