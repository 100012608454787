import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  daoTransactionAddCcPayPost,
  daoTransactionAddCcPayScaPost,
  daoTransactionAddCcPost,
  daoTransactionAddCcScaPost,
  daoTransactionCcPreparePost,
  daoTransactionUseCcPayPost,
  daoTransactionUseCcPayScaPost,
} from 'dao/transactions-cc-dao';
import { Action } from 'types/action';
import { Thunk } from 'types/root';
import {
  AddCcPaylod,
  AddCcPayPayload,
  AddCcResp,
  PreparePayload,
  PrepareResp,
  TransactionsCcState,
  UseCcPayPayload,
  UseCcPayResp,
} from 'types/transactions-cc';

// Prepare Transaction
export const postTransactionPrepare = createAsyncThunk<TransactionsCcState, PreparePayload, Thunk>(
  'transactions/prepare',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionCcPreparePost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

// Add Credit Card
export const postTransactionAddCc = createAsyncThunk<TransactionsCcState, AddCcPaylod, Thunk>(
  'transactions/addCc',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddCcPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

export const postTransactionAddCcSca = createAsyncThunk<TransactionsCcState, AddCcPaylod, Thunk>(
  'transactions/addCcSca',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddCcScaPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

// Use Credit Card to Pay
export const postTransactionUseCcPay = createAsyncThunk<TransactionsCcState, UseCcPayPayload, Thunk>(
  'transactions/useCcPay',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionUseCcPayPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

export const postTransactionUseCcScaPay = createAsyncThunk<TransactionsCcState, UseCcPayPayload, Thunk>(
  'transactions/useCcScaPay',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionUseCcPayScaPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

// Add Credit Card and Pay
export const postTransactionAddCcPay = createAsyncThunk<TransactionsCcState, AddCcPayPayload, Thunk>(
  'transactions/addCcPay',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddCcPayPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

export const postTransactionAddCcScaPay = createAsyncThunk<TransactionsCcState, AddCcPayPayload, Thunk>(
  'transactions/addCcScaPay',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddCcPayScaPost(accountKey, sessionId, payload);
    return response as TransactionsCcState;
  },
);

const initialState: TransactionsCcState = {
  isLoading: false,
  prepareResp: {} as PrepareResp,
  addCcResp: {} as AddCcResp,
  useCcPayResp: {} as UseCcPayResp,
};

const transactionsCcSlice = createSlice({
  name: 'transactions-cc',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Prepare case
      .addCase(postTransactionPrepare.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionPrepare.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.prepareResp = action.payload;
      })
      .addCase(postTransactionPrepare.rejected, (state) => {
        state.isLoading = false;
        state.prepareResp = {} as PrepareResp;
      })
      // Add Credit Card case
      .addCase(postTransactionAddCc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddCc.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addCcResp = action.payload;
      })
      .addCase(postTransactionAddCc.rejected, (state) => {
        state.isLoading = false;
        state.addCcResp = {} as AddCcResp;
      })
      // Add Credit Card SCA case
      .addCase(postTransactionAddCcSca.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddCcSca.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addCcResp = action.payload;
      })
      .addCase(postTransactionAddCcSca.rejected, (state) => {
        state.isLoading = false;
        state.addCcResp = {} as AddCcResp;
      })
      // Use Credit Card to Pay case
      .addCase(postTransactionUseCcPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionUseCcPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.useCcPayResp = action.payload;
      })
      .addCase(postTransactionUseCcPay.rejected, (state) => {
        state.isLoading = false;
        state.useCcPayResp = {} as UseCcPayResp;
      })
      // Use Credit Card to Pay SCA case
      .addCase(postTransactionUseCcScaPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionUseCcScaPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.useCcPayResp = action.payload;
      })
      .addCase(postTransactionUseCcScaPay.rejected, (state) => {
        state.isLoading = false;
        state.useCcPayResp = {} as UseCcPayResp;
      })
      // Add Credit Card and Pay case
      .addCase(postTransactionAddCcPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddCcPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addCcResp = action.payload;
      })
      .addCase(postTransactionAddCcPay.rejected, (state) => {
        state.isLoading = false;
        state.addCcResp = {} as AddCcResp;
      })
      // Add Credit Card and Pay SCA case
      .addCase(postTransactionAddCcScaPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddCcScaPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addCcResp = action.payload;
      })
      .addCase(postTransactionAddCcScaPay.rejected, (state) => {
        state.isLoading = false;
        state.addCcResp = {} as AddCcResp;
      });
  },
});

export default transactionsCcSlice.reducer;
