import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { daoSessionDetailsPost } from 'dao/session-details-dao';
import { Action } from 'types/action';
import { Thunk } from 'types/root';
import { BillingAddress, SessionDetailsState, TransactionProduct } from 'types/session-details';

export const postSessionDetails = createAsyncThunk<SessionDetailsState, void, Thunk>(
  'session/details',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;

    const response = await daoSessionDetailsPost(accountKey, sessionId);
    return response as SessionDetailsState;
  },
);

const initialState: SessionDetailsState = {
  isLoading: false,
  intent: '',
  address: {} as BillingAddress,
  products: [] as TransactionProduct[],
  amount: 0,
  currencyCode: 'INR',
  taxAmount: 0,
  successRedirectUrl: '',
  cancelRedirectUrl: '',
  quoteId: '',
  validUntil: '',
  isSessionExpired: false,
};

const sessionDetailsSlice = createSlice({
  name: 'session-details',
  initialState,
  reducers: {
    setSessionExpired(state, action) {
      state.isSessionExpired = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postSessionDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSessionDetails.fulfilled, (state, action: Action) => {
        const {
          intent,
          address,
          products,
          amount,
          currencyCode,
          taxAmount,
          successRedirectUrl,
          cancelRedirectUrl,
          quoteId,
          validUntil,
        } = action.payload;
        state.isLoading = false;
        state.intent = intent;
        state.address = address;
        state.products = products;
        state.amount = amount;
        state.currencyCode = currencyCode;
        state.taxAmount = taxAmount;
        state.successRedirectUrl = successRedirectUrl;
        state.cancelRedirectUrl = cancelRedirectUrl;
        state.quoteId = quoteId;
        state.validUntil = validUntil;
      })
      .addCase(postSessionDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setSessionExpired } = sessionDetailsSlice.actions;

export default sessionDetailsSlice.reducer;
