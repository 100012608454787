import { CCUIState } from 'types/cc-ui';
import { RootState } from 'types/root';

export const ccUITree = (state: RootState): CCUIState => state.ccUI || {};

export const isCCLoading = (state: RootState): boolean => ccUITree(state).isLoading || false;

export const isCardNumberValid = (state: RootState): boolean => ccUITree(state).isCardNumberValid || false;

export const isCVVValid = (state: RootState): boolean => ccUITree(state).isCVVValid || false;

export const isExpiryValid = (state: RootState): boolean => ccUITree(state).isExpiryValid || false;
