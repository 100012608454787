import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon, BankCardOutlinedIcon } from '@getgo/chameleon-icons/react';
import { RadioButton, RadioGroup, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { paymentMethodTypes, setPaymentType } from 'modules/payment-methods';
import {
  ACH,
  PAYMENT_METHODS_ACH_TYPES,
  PAYMENT_METHODS_CC_TYPES,
  PAYMENT_METHODS_DD_TYPES,
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_DD,
} from 'utils/constants';
import st from 'utils/shared-translations';

import './payment-method-options.css';

const PaymentMethodOptions: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const selectedPaymentTypes = useAppSelector(paymentMethodTypes);

  const hasPaymentType = (paymentTypes: readonly string[]) =>
    selectedPaymentTypes.some((type) => paymentTypes.includes(type));

  const handlePaymentType = (event: any) => {
    const { value } = event.currentTarget;
    dispatch(setPaymentType(value));
  };

  return (
    <RadioGroup
      className="payment-method-options"
      aria-label="payment-method-selector"
      value={''}
      onChange={handlePaymentType}
    >
      {hasPaymentType(PAYMENT_METHODS_CC_TYPES) && (
        <RadioButton className="payment-method-options__radio" value={PAYMENT_TYPE_CC}>
          <div className="payment-method-options__type">
            <BankCardOutlinedIcon size="24px" />
            <Typography tag="h3" variant="body-small-strong">
              {intl.formatMessage(st['payment.credit.debit.card'])}
            </Typography>
          </div>
        </RadioButton>
      )}
      {hasPaymentType(PAYMENT_METHODS_DD_TYPES) && (
        <RadioButton className="payment-method-options__radio" value={PAYMENT_TYPE_DD}>
          <div className="payment-method-options__type">
            <BankAccountOutlinedIcon size="24px" />
            <Typography tag="h3" variant="body-small-strong">
              {intl.formatMessage(st['payment.direct.debit.sepa'])}
            </Typography>
          </div>
        </RadioButton>
      )}
      {hasPaymentType(PAYMENT_METHODS_ACH_TYPES) && (
        <RadioButton className="payment-method-options__radio" value={ACH}>
          <div className="payment-method-options__type">
            <BankAccountOutlinedIcon size="24px" />
            <Typography tag="h3" variant="body-small-strong">
              {intl.formatMessage(st['payment.direct.debit.ach'])}
            </Typography>
          </div>
        </RadioButton>
      )}
    </RadioGroup>
  );
};

export default PaymentMethodOptions;
