import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { daoTransactionDDAddPost, daoTransactionDDPayPost } from 'dao/transaction-dd-dao';
import { Action } from 'types/action';
import { Thunk } from 'types/root';
import { AddDDPayload, AddDDResp, TransactionsDDState, UseDDPayPayload, UseDDPayResp } from 'types/transaction-dd';

// Add DD
export const postTransactionAddDD = createAsyncThunk<TransactionsDDState, AddDDPayload, Thunk>(
  'transactions/addDD',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionDDAddPost(accountKey, sessionId, payload);
    return response as TransactionsDDState;
  },
);

// Pay using DD
export const postTransactionUseDDPay = createAsyncThunk<TransactionsDDState, UseDDPayPayload, Thunk>(
  'transactions/payDD',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionDDPayPost(accountKey, sessionId, payload);
    return response as TransactionsDDState;
  },
);

const initialState: TransactionsDDState = {
  isLoading: false,
  addDDResp: {} as AddDDResp,
  useDDPayResp: {} as UseDDPayResp,
};

const transactionsDDSlice = createSlice({
  name: 'transactions-dd',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // add dd
      .addCase(postTransactionAddDD.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddDD.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addDDResp = action.payload;
      })
      .addCase(postTransactionAddDD.rejected, (state) => {
        state.isLoading = false;
        state.addDDResp = {} as AddDDResp;
      })
      // pay using dd
      .addCase(postTransactionUseDDPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionUseDDPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.useDDPayResp = action.payload;
      })
      .addCase(postTransactionUseDDPay.rejected, (state) => {
        state.isLoading = false;
        state.useDDPayResp = {} as UseDDPayResp;
      });
  },
});

export default transactionsDDSlice.reducer;
