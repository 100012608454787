import { Middleware } from '@reduxjs/toolkit';

import Track, { OnePayAPIError } from 'modules/tracking';

const trackingMiddleware: Middleware = () => (next) => (action) => {
  const { type, error } = action;
  if (type.includes('/rejected')) {
    Track(OnePayAPIError, { api: type, ...error });
  }
  return next(action);
};

export default trackingMiddleware;
