import domHelpers from 'lib/dom-helpers';
import config from 'config';

/**
 * @class CardinalSongbirdWrapper
 * See {@link https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/131806/Getting+Started} for more information.
 */

export class CardinalSongbirdWrapper {
  constructor(currentWindow = window) {
    const url = config.CARDINAL_SONGBIRD_URL;
    this.lib = domHelpers.createScript(url);

    this.currentWindow = currentWindow;
  }

  /**
   * Configures the Cardinal, logging is on for development mode only.
   */
  configure = () => this.lib.then(() => {
    this.currentWindow.Cardinal.configure({
      logging: {
        level: process.env.NODE_ENV === 'development' ? 'on' : 'off'
      }
    });
  })

  /**
   * Initialize the cardinal setup with SCA JWT.
   * @param {object} tokenResp
   */
  initialize = (tokenResp) => {
    this.currentWindow.Cardinal.setup('init', {
      jwt: tokenResp.tokenString
    });
  }

  /**
   * Calls the on method on the Cardinal to subscribe to an event name
   * @param {string} eventName
   * @param {function} callback
   */
  addEventListener = (eventName, callback) => {
    if (typeof this.currentWindow.Cardinal?.on === 'function') {
      this.currentWindow.Cardinal.on(eventName, callback);
    }
  };

  /**
   * Removes the event from listeners list.
   * @param {string} eventName
   */
  removeEventListener = (eventName) => {
    if (typeof this.currentWindow.Cardinal?.off === 'function') {
      this.currentWindow.Cardinal.off(eventName);
    }
  }

  /**
   * trigger bin process after submiting
   * @param {string} cardNo
   */
  trigger = (cardNo) => this.currentWindow.Cardinal.trigger('bin.process', cardNo);

  /**
   * show 3d challange after getting response of payer auth api
   * @param {object} cardinalPayload
   * @param {object} orderPayload
   */
  show3DSChallange = (cardinalPayload, orderPayload) => this.currentWindow.Cardinal.continue('cca', cardinalPayload, orderPayload);
}

export default new CardinalSongbirdWrapper();
