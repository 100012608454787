import { FC } from 'react';
import { useIntl } from 'react-intl';
import { MenuSeparator, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { sessionCurrencyCode, sessionEmail, sessionQuoteId, sessionTotalAmount } from 'modules/session-details';
import { RECEIPIENT } from 'utils/constants';
import st from 'utils/shared-translations';

import './price-info.css';

const PriceInfo: FC = (): JSX.Element => {
  const intl = useIntl();

  const selectedEmail = useAppSelector(sessionEmail);
  const selectedQuoteId = useAppSelector(sessionQuoteId);
  const selectedTotalAmount = useAppSelector(sessionTotalAmount);
  const selectedCurrency = useAppSelector(sessionCurrencyCode);

  return (
    <section className="price-info">
      <ul className="price-info__list">
        <li>
          <Typography tag="p" variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['reference.number'])}
          </Typography>
          <Typography tag="p" variant="body-small">
            {selectedQuoteId}
          </Typography>
        </li>
        <li>
          <Typography tag="p" variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['price.info.recipient'])}
          </Typography>
          <Typography tag="p" variant="body-small">
            {RECEIPIENT}
          </Typography>
        </li>
        <li>
          <Typography tag="p" variant="caption-medium" color="type-color-secondary">
            {intl.formatMessage(st['your.contact.info'])}
          </Typography>
          <Typography tag="p" className="price-info-email" variant="body-small">
            {selectedEmail}
          </Typography>
        </li>
      </ul>
      <MenuSeparator className="price-info__separater" />
      <section className="price-info__total">
        <Typography tag="h1" variant="body-small-strong">
          {intl.formatMessage(st['price.info.total'])}
        </Typography>
        <Typography tag="h1" variant="body-small-strong">
          {intl.formatNumber(selectedTotalAmount, {
            style: 'currency',
            currency: selectedCurrency,
          })}
        </Typography>
      </section>
    </section>
  );
};

export default PriceInfo;
