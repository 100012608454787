import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { daoProcessRenewalPost } from 'dao/cog-dao';
import { COGState, ProcessRenewalPayload } from 'types/cog';
import { Thunk } from 'types/root';

export const postProcessRenewal = createAsyncThunk<Boolean, ProcessRenewalPayload, Thunk>(
  'renewal/process',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await daoProcessRenewalPost(state.globalWrapper.accountKey, payload);
    return response as Boolean;
  },
);

const initialState: COGState = {
  isProcessRenewalLoading: false,
  isProcessRenewalSuccessful: false,
  isProcessRenewalFailed: false,
};

const cogSlice = createSlice({
  name: 'renewals',
  initialState,
  reducers: {
    resetProcessRenewalLoaders(state) {
      state.isProcessRenewalLoading = false;
      state.isProcessRenewalSuccessful = false;
      state.isProcessRenewalFailed = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postProcessRenewal.pending, (state) => {
        state.isProcessRenewalLoading = true;
        state.isProcessRenewalSuccessful = false;
        state.isProcessRenewalFailed = false;
      })
      .addCase(postProcessRenewal.fulfilled, (state) => {
        state.isProcessRenewalLoading = false;
        state.isProcessRenewalSuccessful = true;
        state.isProcessRenewalFailed = false;
      })
      .addCase(postProcessRenewal.rejected, (state) => {
        state.isProcessRenewalLoading = false;
        state.isProcessRenewalSuccessful = false;
        state.isProcessRenewalFailed = true;
      });
  },
});

export const { resetProcessRenewalLoaders } = cogSlice.actions;

export default cogSlice.reducer;
