import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import { DIRECT_DEBIT_FORM } from 'utils/constants';
import st from 'utils/shared-translations';

import './direct-debit-header.css';

interface DirectDebitHeaderProps {
  intent: string;
}

const DirectDebitHeader: FC<DirectDebitHeaderProps> = ({ intent }): JSX.Element => {
  const intl = useIntl();

  const headerString = intent === DIRECT_DEBIT_FORM ? 'dd.form.header' : 'dd.sepa.header';

  return (
    <header className="direct-debit-header">
      <section className="direct-debit-header__label">
        <Typography tag="h1" variant="heading-medium">
          {intl.formatMessage(st[headerString])}
        </Typography>
      </section>
      <Typography variant="body-small">{intl.formatMessage(st['dd.form.header.caption'])}</Typography>
    </header>
  );
};

export default DirectDebitHeader;
