import AppConfig, { AppConfiguration } from './AppConfig';
import getEnvironment from './getEnvironment';

interface Config extends AppConfiguration {
  ENV: string;
  AUTH_TOKEN_STORAGE_KEY: string;
}

const config: Config = {
  ...AppConfig[getEnvironment() || 'local'],
  AUTH_TOKEN_STORAGE_KEY: 'one-payment-auth-token',
  ENV: getEnvironment(),
};

export default config;
