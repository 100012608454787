import { FC } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, Dialog, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { isProcessQuoteLoading } from 'modules/ucs/ucs-selector';
import st from 'utils/shared-translations';

import './process-quote-loader.css';

const ProcessQuoteLoader: FC = (): JSX.Element => {
  const intl = useIntl();

  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);

  return (
    <Dialog open={selectedQuoteProcessIsLoading} size="large" role="alertdialog" title="">
      <div className="process-quote-loader">
        <CircularProgress />
        <Typography tag="h1" variant="heading-small" color="type-color-default">
          {intl.formatMessage(st['process.quote.loader.patience.message'])}
        </Typography>
      </div>
    </Dialog>
  );
};

export default ProcessQuoteLoader;
