import { configureStore } from '@reduxjs/toolkit';

import trackingMiddleware from './middlewares/tracking-middleware';
import rootReducer from './reducer';

/**
 * NOTE: Redux Toolkit comes with default configuration and it's recommended to use this
 * instead of configuring thunk, middleware and createStore.
 * https://redux.js.org/usage/migrating-to-modern-redux
 */
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(trackingMiddleware),
});

export default store;
