import config from 'config';
import fetchWithToken from './provider/fetch';

const getQuoteEndPoint = (accountKey: string): string => `${config.UNIFIED_COMMERCE_URL}/rest/v2/quote/${accountKey}`;

export const daoProcessQuotePost = (accountKey: string, quoteCode: string, payload: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${getQuoteEndPoint(accountKey)}/process/${quoteCode}`,
    method: 'POST',
    body: JSON.stringify(payload),
  });

export default {
  daoProcessQuotePost,
};
