import { FC } from 'react';

import DirectDebitForm from 'components/direct-debit-form';
import DirectDebitHeader from 'components/direct-debit-header';
import DirectDebitInfo from 'components/direct-debit-info';
import { useAppSelector } from 'hooks';
import { directDebitList } from 'modules/payment-methods';
import { DdDetails } from 'types/payment-method';
import { DIRECT_DEBIT_FORM, DIRECT_DEBIT_INFO } from 'utils/constants';

const DirectDebitPay: FC = () => {
  const selectedDDList = useAppSelector(directDebitList);

  const isDDListEmpty = !selectedDDList.length;

  return (
    <>
      <DirectDebitHeader intent={isDDListEmpty ? DIRECT_DEBIT_FORM : DIRECT_DEBIT_INFO} />
      {selectedDDList.map((directDebit: DdDetails) => (
        <DirectDebitInfo key={directDebit.paymentMethodKey} directDebit={directDebit} />
      ))}
      {isDDListEmpty && <DirectDebitForm />}
    </>
  );
};

export default DirectDebitPay;
