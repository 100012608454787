import getEnvironment from 'config/getEnvironment';

export const getProductAdminDataFromCookie = () => {
  const adminInfo = document.cookie
    .split('; ')
    .find((row) => row.startsWith('productAdminFormData'))
    ?.split('=')[1];

  return adminInfo ? JSON.parse(adminInfo) : {};
};

export const removeProductAdminDataFromCookie = () => {
  const getCookieDomain = getEnvironment() === 'local' ? 'localhost' : '.goto.com';
  // Remove the cookie that store product admin form data
  document.cookie = `productAdminFormData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${getCookieDomain}; path=/;`;
};
