import config from 'config';
import { auth } from 'lib/auth-provider';

interface FetchProps {
  reqUrl: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: any;
  body: any;
  isDownloadable?: boolean;
}

const fetchWithToken = async ({ reqUrl, method = 'GET', headers = {}, body, isDownloadable }: FetchProps) => {
  const token = JSON.parse(sessionStorage.getItem(config.AUTH_TOKEN_STORAGE_KEY)!) || { access_token: '' };
  const { access_token } = token;

  const fetchResponse = await fetch(reqUrl, {
    method,
    headers: {
      Accept: 'text/html, application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      Tenant: 'one-payment',
      ...headers,
    },
    body,
  });

  if (fetchResponse.status === 401) {
    sessionStorage.clear();
    auth.login({
      state: { inflightRequest: window.location.href },
    });
    return;
  }

  let result = '';
  if (isDownloadable) {
    const blob = await fetchResponse.blob();
    const header = fetchResponse.headers.get('Content-Disposition');
    let filename = 'invoice';
    if (header) {
      filename = header.split('filename=')?.[1];
      filename = filename.replace(/['"]+/g, '');
      filename = filename.split('.')?.[0];
    }

    // Open in new tab
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.target = '_blank';
    a.click();
    a.remove();
  } else {
    result = await fetchResponse.text();
  }

  if (fetchResponse.ok) {
    try {
      return result ? JSON.parse(result) : {};
    } catch (e) {
      return result;
    }
  }
  const errorResponse = {
    status: fetchResponse.status,
    statusText: fetchResponse.statusText,
    data: result ? JSON.parse(result) : {},
  };
  return Promise.reject(errorResponse);
};

export default fetchWithToken;
