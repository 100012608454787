import { RootState } from 'types/root';
import { AddAchResp, TransactionsAchState, UseAchPayResp } from 'types/transaction-ach';

export const transactionsAchTree = (state: RootState): TransactionsAchState => state.transactionsAch || {};

export const transactionsAchIsLoading = (state: RootState): boolean => transactionsAchTree(state).isLoading || false;

export const addAchResp = (state: RootState): AddAchResp => transactionsAchTree(state).addAchResp || {};

export const useAchPayResp = (state: RootState): UseAchPayResp => transactionsAchTree(state).useAchPayResp || {};
