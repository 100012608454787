import { FC } from 'react';

import ACHForm from 'components/ach-form';
import ACHHeader from 'components/ach-header';
import { ACH_FORM } from 'utils/constants';

const ACHMethod: FC = (): JSX.Element => (
  <>
    <ACHHeader intent={ACH_FORM} />
    <ACHForm />
  </>
);

export default ACHMethod;
