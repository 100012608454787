import AppConfig, { Environments } from './AppConfig';

const getEnvironment = (): Environments | '' =>
  (process.env.REACT_APP_ENV as Environments) ||
  (AppConfig &&
    `${Object.keys(AppConfig).find((env) =>
      window.location.hostname.includes(new URL(AppConfig[env].APP_URL).hostname),
    )}`) ||
  '';

export default getEnvironment;
