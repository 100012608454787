import config from 'config';
import fetchWithToken from './provider/fetch';

const paymentMethodsEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/paymentMethods`;

const paymentMethodTypesEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/paymentMethodTypeRestrictions`;

export const daoPaymentMethodsPost = (accountKey: string, sessionId: string): Promise<{}> =>
  fetchWithToken({ reqUrl: `${paymentMethodsEndPoint(accountKey, sessionId)}/get`, method: 'POST', body: null });

export const daoPaymentMethodTypesPost = (accountKey: string, sessionId: string): Promise<{}> =>
  fetchWithToken({ reqUrl: paymentMethodTypesEndPoint(accountKey, sessionId), method: 'POST', body: null });

export const daoPaymentMethodsDeletePost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${paymentMethodsEndPoint(accountKey, sessionId)}/delete`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoPaymentMethodsDefaultSetPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${paymentMethodsEndPoint(accountKey, sessionId)}/default/set`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export default {
  daoPaymentMethodsPost,
  daoPaymentMethodsDeletePost,
  daoPaymentMethodsDefaultSetPost,
};
