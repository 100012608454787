import { FC } from 'react';
import { useIntl } from 'react-intl';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaLightModeIcon } from '@getgo/chameleon-icons/react';
import { AlertV2, Typography } from '@getgo/chameleon-web-react-wrapper';

import st from 'utils/shared-translations';

import './credit-card-header.css';

const CreditCardHeader: FC = () => {
  const intl = useIntl();

  return (
    <header className="credit-card-header">
      <section className="credit-card-header__label">
        <Typography tag="h1" variant="heading-medium">
          {intl.formatMessage(st['price.info.card'])}
        </Typography>
        <div className="credit-card-header__icons">
          <VisaLightModeIcon size="30px" />
          <MastercardIcon size="30px" />
          <DiscoverIcon size="30px" />
          <AmexIcon size="30px" />
        </div>
      </section>
      <AlertV2 className="payment-method-info__cc-info">
        <Typography tag="h2" variant="caption-medium">
          {intl.formatMessage(st['creditcard.limit.info'])}
        </Typography>
      </AlertV2>
    </header>
  );
};

export default CreditCardHeader;
