import { FC } from 'react';

import AchForm from 'components/ach-form';
import ACHHeader from 'components/ach-header';
import ACHInfo from 'components/ach-info';
import { useAppSelector } from 'hooks';
import { achList } from 'modules/payment-methods';
import { AchDetails } from 'types/payment-method';
import { ACH_FORM, ACH_INFO } from 'utils/constants';

const ACHPay: FC = () => {
  const selectedAchList = useAppSelector(achList);

  const isACHListEmpty = !selectedAchList.length;

  return (
    <>
      <ACHHeader intent={isACHListEmpty ? ACH_FORM : ACH_INFO} />
      {selectedAchList.map((ach: AchDetails) => (
        <ACHInfo key={ach.paymentMethodKey} achDetails={ach} />
      ))}
      {isACHListEmpty && <AchForm />}
    </>
  );
};

export default ACHPay;
