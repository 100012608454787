import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Skeleton, TextField, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { isCCLoading, isExpiryValid, setExpiryValidity } from 'modules/cc-ui';
import { transactionsCcIsLoading } from 'modules/transactions-cc';
import { CREDIT_CARD_EXP } from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-exp.css';

interface CreditCardExpProps {
  formValues: any;
  setFormValues: any;
  touched: any;
  setFieldTouched: any;
  handleChange: any;
}

const CreditCardExp: FC<CreditCardExpProps> = ({
  formValues,
  setFormValues,
  touched,
  setFieldTouched,
  handleChange,
}): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [errorMsgKey, setErrorMsgKey] = useState('');

  const selectedTransactionCcLoading = useAppSelector(transactionsCcIsLoading);

  const selectedCCIsLoading = useAppSelector(isCCLoading);
  const selectedExpIsValid = useAppSelector(isExpiryValid);

  const setExpirationDate = (e) => {
    const { name } = e.target;
    let { value } = e.target;

    if (!isNaN(value) || (value.length > 2 && !isNaN(value.substring(0, 2)) && !isNaN(value.substring(3)))) {
      if (value.length === 2) {
        value += '/';
      }
      checkExpDate(value);
    } else {
      setErrorMsgKey('creditcardform.date.validation.general.errormessage');
      dispatch(setExpiryValidity(false));
    }
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };

  /**
   * This method returns the exp validation message based on the date provided.
   * It also sets the expiry validity in the store.
   */
  const checkExpDate = (date) => {
    let errorMsgKey = '';
    let isValid = false;

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 22;

    const monthYear = date.split('/');
    const month = Number(monthYear[0]);
    const year = Number(`20${monthYear[1]}`.slice(-4));

    if (monthYear.length === 2 && Number.isInteger(month) && Number.isInteger(year)) {
      if (month >= 1 && month <= 12 && ((year === currentYear && month < currentMonth) || year < currentYear)) {
        errorMsgKey = 'creditcardform.date.validation.past.errormessage';
        isValid = false;
      } else if (month >= 1 && month <= 12 && year >= currentYear && year <= maxYear) {
        isValid = true;
      } else {
        errorMsgKey = 'creditcardform.date.validation.invalid.errormessage';
        isValid = false;
      }
    } else {
      errorMsgKey = 'creditcardform.date.validation.general.errormessage';
      isValid = false;
    }

    setErrorMsgKey(errorMsgKey);
    dispatch(setExpiryValidity(isValid));
  };

  const isExpInvalidMessage =
    !!formValues[CREDIT_CARD_EXP] && touched[CREDIT_CARD_EXP] && !!errorMsgKey && !selectedExpIsValid;

  const isLoading = selectedTransactionCcLoading || selectedCCIsLoading;

  return (
    <section className="credit-card-exp">
      {isLoading ? (
        <>
          <Typography
            variant="button-small"
            tag="p"
            color="type-color-default"
            className="credit-card-exp--margin-bottom"
          >
            {intl.formatMessage(st['creditcardform.date.expiration'])}
          </Typography>
          <Skeleton size="large" variant="rectangle" />
        </>
      ) : (
        <TextField
          name={CREDIT_CARD_EXP}
          fieldsize="medium"
          placeholder="MM/YYYY"
          value={formValues[CREDIT_CARD_EXP]}
          helperText={isExpInvalidMessage ? intl.formatMessage(st[errorMsgKey]) : ''}
          error={isExpInvalidMessage}
          onBlur={() => setFieldTouched(CREDIT_CARD_EXP, true)}
          onFocus={() => setFieldTouched(CREDIT_CARD_EXP, false)}
          onChange={(e) => {
            handleChange(e);
            setExpirationDate(e);
          }}
          tabIndex={0}
          fullwidth
        >
          {intl.formatMessage(st['creditcardform.date.expiration'])}
        </TextField>
      )}
    </section>
  );
};

export default CreditCardExp;
