import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import st from 'utils/shared-translations';
import { achAccountTypeList } from './constants';

export const getAchFormValidationSchema = (intl: IntlShape): {} => {
  const validationSchema = {
    accountNumber: Yup.string()
      .trim()
      .nullable()
      .required(intl.formatMessage(st['ach.form.validation.required']))
      .min(4, intl.formatMessage(st['ach.form.error.account.number.short']))
      .max(17, intl.formatMessage(st['ach.form.error.account.number.long'])),
    bankTransitNumber: Yup.string()
      .trim()
      .nullable()
      .required(intl.formatMessage(st['ach.form.validation.required']))
      .length(9, intl.formatMessage(st['ach.form.error.routing.number'])),
    accountType: Yup.string()
      .oneOf(achAccountTypeList)
      .required(intl.formatMessage(st['ach.form.validation.required'])),
  };

  return Yup.object().shape(validationSchema);
};
