import config from 'config';
import fetchWithToken from './provider/fetch';

const transactionsCCEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/transaction/cc`;

// Prepare transaction
export const daoTransactionCcPreparePost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/prepare`,
    method: 'POST',
    body: JSON.stringify(data),
  });

// Add credit card
export const daoTransactionAddCcPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/addCreditCardWithoutPayment`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionAddCcScaPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/addCreditCardWithoutPayment/verifySca`,
    method: 'POST',
    body: JSON.stringify(data),
  });

// Add credit card and pay
export const daoTransactionAddCcPayPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/addCreditCardWithPayment`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionAddCcPayScaPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/addCreditCardWithPayment/verifySca`,
    method: 'POST',
    body: JSON.stringify(data),
  });

// Use stored credit card to pay
export const daoTransactionUseCcPayPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/useStoredCreditCardWithPayment`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionUseCcPayScaPost = (accountKey: string, sessionId: string, data: {}): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsCCEndPoint(accountKey, sessionId)}/useStoredCreditCardWithPayment/verifySca`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export default {
  daoTransactionCcPreparePost,
  daoTransactionAddCcPost,
  daoTransactionAddCcScaPost,
  daoTransactionAddCcPayPost,
  daoTransactionAddCcPayScaPost,
  daoTransactionUseCcPayPost,
  daoTransactionUseCcPayScaPost,
};
