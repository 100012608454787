import { createSlice } from '@reduxjs/toolkit';

import { ErrorsState } from 'types/errors';

const errorSlice = createSlice({
  name: 'errorSlice',

  initialState: {
    errorAlerts: {},
  } as ErrorsState,

  reducers: {
    showErrorSnack: (state, action) => {
      const alertObj = {
        key: action.payload.id,
        timestamp: Date.now(),
        msgObj: action.payload,
      };
      state.errorAlerts = { ...state.errorAlerts, [action.payload.id]: alertObj };
    },

    dismissErrorSnack: (state, action) => {
      const alerts = { ...state.errorAlerts };
      delete alerts[action.payload];
      state.errorAlerts = alerts;
    },
  },
});

export const { showErrorSnack, dismissErrorSnack } = errorSlice.actions;

export default errorSlice.reducer;
