import { FC, ReactNode } from 'react';
import Auth from '@getgo/auth-client';
import { IAuthOptions } from '@getgo/auth-client/lib/auth';

import config from 'config';

const options: IAuthOptions = {
  client_id: config.CLIENT_ID,
  url: config.AUTH_SERVICE_URL,
  redirect_url: `${window.location.origin}`,
  enableTokenRefresh: true,
};

export const auth = new Auth(options);

interface GetGoAuthProviderProps {
  children?: ReactNode;
}

const AuthProvider: FC<GetGoAuthProviderProps> = ({ children }) => {
  const tokenName = config.AUTH_TOKEN_STORAGE_KEY;

  const tokenFromCb = auth.init();
  const token = sessionStorage.getItem(tokenName);
  if (tokenFromCb) {
    sessionStorage.setItem(tokenName, JSON.stringify(tokenFromCb));

    if (tokenFromCb.state) {
      window.location.assign(tokenFromCb.state.inflightRequest);
    }
    return null;
  } else {
    if (token && token !== 'null') {
      return children;
    } else {
      auth.login({
        state: { inflightRequest: window.location.href },
      });
      return null;
    }
  }
};

export default AuthProvider;
