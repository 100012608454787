import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import ACHDDBuyNow from 'components/ach-dd-buy-now';
import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { DdDetails } from 'types/payment-method';
import { PAYMENT_TYPE_DD } from 'utils/constants';
import st from 'utils/shared-translations';

import './direct-debit-info.css';

interface DirectDebitInfoProps {
  directDebit: DdDetails;
}

const DirectDebitInfo: FC<DirectDebitInfoProps> = ({ directDebit }): JSX.Element => {
  const intl = useIntl();

  return (
    <section className="direct-debit-info">
      <div className="direct-debit-info__header">
        <BankAccountOutlinedIcon size="24px" />
        <DefaultDeleteCTAs
          isDefault={directDebit.isDefault}
          paymentMethodKey={directDebit.paymentMethodKey}
          paymentType={PAYMENT_TYPE_DD}
        />
      </div>
      <section className="direct-debit-info__number">
        <Typography tag="h3" variant="body-small" gutterBottom>
          {intl.formatMessage(st['dd.mandate.id'])}: {directDebit?.mandateId}
        </Typography>
        <Typography tag="h3" variant="body-small">
          {intl.formatMessage(st['dd.bank.name'])}: {directDebit?.bankName}
        </Typography>
      </section>

      <ACHDDBuyNow paymentDetails={directDebit} paymentType={PAYMENT_TYPE_DD} />
    </section>
  );
};

export default DirectDebitInfo;
