import { createSlice } from '@reduxjs/toolkit';

import { CCUIState } from 'types/cc-ui';

const ccUISlice = createSlice({
  name: 'ccUI',

  initialState: {
    isLoading: true,
    isCardNumberValid: false,
    isCVVValid: false,
    isExpiryValid: false,
  } as CCUIState,

  reducers: {
    setCCLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCardNumberValidity(state, action) {
      state.isCardNumberValid = action.payload;
    },
    setCVVValidity(state, action) {
      state.isCVVValid = action.payload;
    },
    setExpiryValidity(state, action) {
      state.isExpiryValid = action.payload;
    },
  },
});

export const { setCCLoading, setCardNumberValidity, setCVVValidity, setExpiryValidity } = ccUISlice.actions;

export default ccUISlice.reducer;
