import { v1 as uuidv1 } from 'uuid';

import config from 'config';
import domHelpers from 'lib/dom-helpers';

let libLoadedPromise;

export class CybersourceFingerprint {
  trackingUrl: string;

  /**
   * Create a UUID, remove hyphens and convert to uppercase
   */
  static id = uuidv1().replace(new RegExp(/-/g), '').toUpperCase();

  constructor() {
    this.trackingUrl = config.CYBERSOURCE.fingerprint_url;
  }

  /**
   * Load the Cybersource fingerprinting JS
   * @param {country}
   * @returns {Promise} Promise that resolves when the script is successfully loaded; rejects otherwise
   */
  load = (country) => {
    // only load the library once
    if (libLoadedPromise) {
      return libLoadedPromise;
    }

    const region = config.CYBERSOURCE.regions[country] || config.CYBERSOURCE.regions.EU;
    this.trackingUrl = `${this.trackingUrl}?org_id=${config.CYBERSOURCE.org_id}&session_id=${region.session_id}${CybersourceFingerprint.id}`;

    libLoadedPromise = domHelpers.createScript(this.trackingUrl);

    return libLoadedPromise;
  };
}

export default new CybersourceFingerprint();
