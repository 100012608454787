import amplitudeJS from 'amplitude-js';

import config from 'config';
import { AmplitudeRevenue } from 'types/tracking';

/**
 * See {@link https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference Amplitude Documentation} for usage.
 */
export class AmplitudeWrapper {
  consoleObj: {
    log: Function;
  };
  amplitudeInstance: {
    init: Function;
    setUserId: Function;
    setUserProperties: Function;
    setVersionName: Function;
    logEvent: Function;
    logRevenueV2: Function;
  };

  revenueInstance: {
    setProductId: Function;
    setRevenueType: Function;
    setPrice: Function;
    setEventProperties: Function;
  };

  constructor(consoleObj = console) {
    this.consoleObj = consoleObj;
    this.revenueInstance = new amplitudeJS.Revenue();
    this.amplitudeInstance = amplitudeJS.getInstance(config.AMPLITUDE.project);
    // NOTE: The flag `amplitude.disabled` in AppConfig controls whether logs are sent to Amplitude or appear in the console.
    // It is true by default to prevent excessive Amplitude logging when in development. Simply edit to false to log to Amplitude.
    if (config.AMPLITUDE.disabled) {
      this.stub('reason: Disabled');
    }
  }

  initialize = (userKey: string): void => {
    this.amplitudeInstance.init(config.AMPLITUDE.apiKey, null, {
      includeReferrer: true,
    });
    this.amplitudeInstance.setUserId(userKey);
    this.amplitudeInstance.setVersionName('0.0.0-development-SNAPSHOT');
  };

  /**
   * Stub out the Amplitude methods that would fire off network calls
   */
  stub = (reason: string): void => {
    this.amplitudeInstance.logEvent = (eventName: any, payload: object) =>
      this.consoleObj.log(
        `[Amplitude Stubbed - logEvent - ${reason}] - Would have sent ${eventName}`,
        this.sanitize(payload),
      );
    this.amplitudeInstance.logRevenueV2 = (revenue: any) =>
      this.consoleObj.log(`[Amplitude Stubbed - logRevenueV2 - ${reason}] - Would have sent logRevenueV2`, revenue);
    this.amplitudeInstance.setUserProperties = (userData: any) =>
      this.consoleObj.log(`[Amplitude Stubbed - setUserProperties - ${reason}] - Would have sent userData`, userData);
  };

  /**
   * Recursively stringify collections, but respecting documented Amplitude limitations here: https://developers.amplitude.com/#setting-event-properties
   */
  serialize = (value: string | object | []): any => {
    if (Array.isArray(value)) {
      if (value.some((val) => Array.isArray(val) || typeof val === 'object')) {
        return value.map(this.serialize).reduce((acc, v, index) => {
          acc[index] = v;
          return acc;
        }, {});
      }
      return value.map(this.serialize);
    }
    if (typeof value === 'object') {
      return Object.keys(value).reduce((acc, key) => {
        acc[key] = this.serialize(value[key]);
        return acc;
      }, {});
    }
    return value;
  };

  /**
   * Sanitizes data for use in Amplitude. Amplitude does not like the following:
   * - undefined as a value
   * - collections (arrays of objects)
   */
  sanitize = (payload: object): object => {
    const cleanData = {};
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      // ignore undefined/null values
      if (value) {
        cleanData[key] = this.serialize(value);
      }
    });
    return cleanData;
  };

  /**
   * Adds more identifying information about a user. Used to associate a user in Amplitude with their LD experiments, for example
   * @param {object} userData
   */
  identify = (userData: object): void => this.amplitudeInstance.setUserProperties(userData);

  /**
   * It tracks the revenue once an order is completed
   * @param {object} payload
   */
  trackRevenue = (payload: AmplitudeRevenue): void => {
    const revenue = this.revenueInstance
      .setProductId(payload.productId)
      .setRevenueType(payload.orderType)
      .setPrice(payload.orderItemAmount)
      .setQuantity(payload.quantity)
      .setEventProperties({
        currencyCode: payload.currencyCode,
        paymentMethod: payload.paymentMethod,
        purchaseFlow: payload.purchaseFlow,
        productFamilyKey: payload.productFamilyKey,
        quoteId: payload.quoteId,
        paymentPlan: payload.paymentPlan,
      });
    this.amplitudeInstance.logRevenueV2(revenue);
  };

  track = (eventName: string, payload: object): void =>
    this.amplitudeInstance.logEvent(eventName, this.sanitize(payload));
}

export default process.env.NODE_ENV === 'test'
  ? { initialize: () => {}, track: () => {}, trackRevenue: () => {} }
  : new AmplitudeWrapper();
