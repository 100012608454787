import config from 'config';
import { AddAchPayload, UseAchPayPayload } from 'types/transaction-ach';
import fetchWithToken from './provider/fetch';

const transactionsACHEndPoint = (accountKey: string, sessionId: string): string =>
  `${config.COPAS_URL}/payment/ui/cip/v1/${accountKey}/session/${sessionId}/transaction/ach`;

export const daoTransactionAchAddPost = (accountKey: string, sessionId: string, data: AddAchPayload): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsACHEndPoint(accountKey, sessionId)}/add`,
    method: 'POST',
    body: JSON.stringify(data),
  });

export const daoTransactionAchPayPost = (accountKey: string, sessionId: string, data: UseAchPayPayload): Promise<{}> =>
  fetchWithToken({
    reqUrl: `${transactionsACHEndPoint(accountKey, sessionId)}/pay`,
    method: 'POST',
    body: JSON.stringify(data),
  });
