import { COGState } from 'types/cog';
import { RootState } from 'types/root';

export const cogTree = (state: RootState): COGState => state.cog || {};

export const isProcessRenewalLoading = (state: RootState): boolean => cogTree(state).isProcessRenewalLoading || false;

export const isProcessRenewalFailed = (state: RootState): boolean => cogTree(state).isProcessRenewalFailed || false;

export const isProcessRenewalSuccessful = (state: RootState): boolean =>
  cogTree(state).isProcessRenewalSuccessful || false;
