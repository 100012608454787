import { FC } from 'react';

import DirectDebitForm from 'components/direct-debit-form';
import DirectDebitHeader from 'components/direct-debit-header';
import { DIRECT_DEBIT_FORM } from 'utils/constants';

const DirectDebitMethod: FC = (): JSX.Element => (
  <>
    <DirectDebitHeader intent={DIRECT_DEBIT_FORM} />
    <DirectDebitForm />
  </>
);

export default DirectDebitMethod;
