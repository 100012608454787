import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import { ACH_FORM } from 'utils/constants';
import st from 'utils/shared-translations';

import './ach-header.css';

interface ACHHeaderProps {
  intent: string;
}

const ACHHeader: FC<ACHHeaderProps> = ({ intent }): JSX.Element => {
  const intl = useIntl();

  const headerString = intent === ACH_FORM ? 'ach.enter.bank.account.info' : 'ach.your.bank.info';

  return (
    <header className="ach-header">
      <section className="ach-header__label">
        <Typography tag="h1" variant="heading-medium">
          {intl.formatMessage(st[headerString])}
        </Typography>
      </section>
    </header>
  );
};

export default ACHHeader;
