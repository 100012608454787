import { createSlice } from '@reduxjs/toolkit';

import { GlobalWrapperState } from 'types/global-wrapper';

const globalWrapperSlice = createSlice({
  name: 'globalWrapper',

  initialState: {
    isLoading: true,
    accountKey: '',
    locale: 'en_US',
    theme: 'light',
    sessionId: '',
    partnerType: '',
    renewalDate: '',
  } as GlobalWrapperState,

  reducers: {
    hideGlobalLoading(state) {
      state.isLoading = false;
    },
    setGlobalAccountKey(state, action) {
      state.accountKey = action.payload;
    },
    setGlobalLocale(state, action) {
      state.locale = action.payload;
    },
    setGlobalTheme(state, action) {
      state.theme = action.payload;
    },
    setGlobalSessionId(state, action) {
      state.sessionId = action.payload;
    },
    setPartnerType(state, action) {
      state.partnerType = action.payload;
    },
    setRenewalDate(state, action) {
      state.renewalDate = action.payload;
    },
  },
});

export const {
  hideGlobalLoading,
  setGlobalAccountKey,
  setGlobalLocale,
  setGlobalTheme,
  setGlobalSessionId,
  setPartnerType,
  setRenewalDate,
} = globalWrapperSlice.actions;

export default globalWrapperSlice.reducer;
