import { FC, useState } from 'react';
import { useAsync, useLifecycles } from 'react-use';
import { Form, Formik } from 'formik';

import CreditCardAddPayButton from 'components/credit-card-add-pay-button';
import CreditCardCvv from 'components/credit-card-cvv';
import CreditCardExp from 'components/credit-card-exp';
import CreditCardNumber from 'components/credit-card-number';
import CreditCardSaveButton from 'components/credit-card-save-button';
import { useAppDispatch, useAppSelector } from 'hooks';
import FlexMicroform from 'lib/cybersource-flex-microform';
import { isCardNumberValid, isCCLoading, isCVVValid, isExpiryValid, setCCLoading } from 'modules/cc-ui';
import { showErrorSnack } from 'modules/error';
import { accountKey } from 'modules/global-wrapper';
import { postTransactionPrepare } from 'modules/transactions-cc';
import {
  CREDIT_CARD_ADD_PAY,
  CREDIT_CARD_CVV,
  CREDIT_CARD_DEFAULT,
  CREDIT_CARD_EXP,
  CREDIT_CARD_NUMBER,
  CREDIT_CARD_SAVE,
} from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-form.css';

interface CreditCardFormProps {
  intent: string;
}

const CreditCardForm: FC<CreditCardFormProps> = ({ intent }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isMicroformReady, setMicroformReady] = useState(false);
  const [flexFormToken, setFlexFormToken] = useState('');
  const [formValues, setFormValues] = useState({
    [CREDIT_CARD_NUMBER]: '',
    [CREDIT_CARD_CVV]: '',
    [CREDIT_CARD_EXP]: '',
    [CREDIT_CARD_DEFAULT]: false,
  });

  const selectedGlobalAccountKey = useAppSelector(accountKey);

  const selectedCCIsLoading = useAppSelector(isCCLoading);
  const selectedCardNumberIsValid = useAppSelector(isCardNumberValid);
  const selectedCVVIsValid = useAppSelector(isCVVValid);
  const selectedExpIsValid = useAppSelector(isExpiryValid);

  useLifecycles(
    () => {
      dispatch(setCCLoading(false));
      FlexMicroform.teardown();
      setFormValues({
        [CREDIT_CARD_NUMBER]: '',
        [CREDIT_CARD_CVV]: '',
        [CREDIT_CARD_EXP]: '',
        [CREDIT_CARD_DEFAULT]: false,
      });
    },
    () => FlexMicroform.teardown(),
  );

  // Call Prepare Transaction once the global account key is available
  useAsync(async () => {
    if (selectedGlobalAccountKey && !selectedCCIsLoading) {
      try {
        const resp: any = await dispatch(postTransactionPrepare({ paymentMethodKey: null }));
        const { keyId } = resp.payload;
        createMicroForm(keyId);
      } catch {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
      }
    }
  }, [selectedGlobalAccountKey, selectedCCIsLoading]);

  // Create micro form from the keyId generated in prepare transaction call
  const createMicroForm = (keyId) => {
    FlexMicroform.createMicroform(keyId)
      .then(() => {
        setMicroformReady(true);
      })
      .catch(() => {
        setMicroformReady(false);
      });
  };

  // Flexform requires month in the form of two digit, e.g., '01', '06' '10'..
  const getExpirationPayload = (expDate) => {
    const monthYear = expDate.split('/');
    const month = Number(monthYear[0]);
    const year = Number(`20${monthYear[1]}`.slice(-4));

    return {
      expirationMonth: `0${month}`.slice(-2),
      expirationYear: `20${year}`.slice(-4),
    };
  };

  // Submission handler for the credit card form
  const onSubmit = (values) => {
    // Payload for getting FlexForm token.
    const payload = getExpirationPayload(values[CREDIT_CARD_EXP]);

    return new Promise(() =>
      FlexMicroform.createToken(payload, (error, tokenData) => {
        if (error) {
          return dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        }

        setFlexFormToken(tokenData);
        dispatch(setCCLoading(true));
      }),
    );
  };

  const isFormInvalid =
    !formValues[CREDIT_CARD_NUMBER] ||
    !formValues[CREDIT_CARD_CVV] ||
    !formValues[CREDIT_CARD_EXP] ||
    !selectedCardNumberIsValid ||
    !selectedCVVIsValid ||
    !selectedExpIsValid;

  return (
    <Formik initialValues={formValues} onSubmit={onSubmit}>
      {({ handleChange, isSubmitting, setFieldTouched, touched }) => (
        <Form className="credit-card-form">
          <CreditCardNumber
            FlexMicroform={FlexMicroform}
            formValues={formValues}
            setFormValues={setFormValues}
            isMicroformReady={isMicroformReady}
          />
          <div className="credit-card-form__exp-cvv">
            <CreditCardExp
              formValues={formValues}
              setFormValues={setFormValues}
              touched={touched}
              setFieldTouched={setFieldTouched}
              handleChange={handleChange}
            />
            <CreditCardCvv
              FlexMicroform={FlexMicroform}
              formValues={formValues}
              setFormValues={setFormValues}
              isMicroformReady={isMicroformReady}
            />
          </div>
          {
            {
              [CREDIT_CARD_SAVE]: (
                <CreditCardSaveButton
                  isSubmitting={isSubmitting}
                  isFormInvalid={isFormInvalid}
                  flexFormToken={flexFormToken}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              ),
              [CREDIT_CARD_ADD_PAY]: (
                <CreditCardAddPayButton
                  isSubmitting={isSubmitting}
                  isFormInvalid={isFormInvalid}
                  flexFormToken={flexFormToken}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              ),
            }[intent]
          }
        </Form>
      )}
    </Formik>
  );
};

export default CreditCardForm;
